/* eslint-disable @typescript-eslint/no-empty-function */
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';

Vue.use(VueRouter);

import minimatch from 'minimatch';
import configProd from '@/config/env/production/config';
import configDev from '@/config/env/development/config';
let config = null as typeof configProd | null;
if (process.env.NODE_ENV === 'production') {
  config = configProd;
} else {
  config = configDev;
}

function shouldReload(path: string): boolean {
  if (!config) return false;
  return config.v2PathPatternsToReload.some((pattern) => minimatch(path, pattern));
}

import AmplifyAuth, { CognitoUser } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';

import VueGtag from 'vue-gtag';

import { getUser } from '@/graphql/queries';

const Home = () => import(/* webpackChunkName: "Home" */ '@/views/Home.vue');
const NotFound = () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue');
const Complete = () => import(/* webpackChunkName: "Complete" */ '@/views/Complete.vue');
const TermsOfService = () => import(/* webpackChunkName: "TermsOfService" */ '@/views/TermsOfService.vue');
const PrivacyPolicy = () => import(/* webpackChunkName: "PrivacyPolicy" */ '@/views/PrivacyPolicy.vue');
const Contact = () => import(/* webpackChunkName: "Contact" */ '@/views/Contact.vue');
const Offer = () => import(/* webpackChunkName: "Offer" */ '@/views/Offer.vue');
const Faq = () => import(/* webpackChunkName: "Faq" */ '@/views/Faq.vue');
const Price = () => import(/* webpackChunkName: "Price" */ '@/views/Price.vue');
const TrainingPlan = () => import(/* webpackChunkName: "TrainingPlan" */ '@/views/TrainingPlan.vue');
const Gallery = () => import(/* webpackChunkName: "Gallery" */ '@/views/Gallery.vue');
const SignIn = () => import(/* webpackChunkName: "SignIn" */ '@/views/SignIn.vue');
const SignUp = () => import(/* webpackChunkName: "SignUp" */ '@/views/SignUp.vue');
const Verify = () => import(/* webpackChunkName: "Verify" */ '@/views/Verify.vue');
const Admin = () => import(/* webpackChunkName: "Admin" */ '@/views/Admin.vue');
const MyPage = () => import(/* webpackChunkName: "MyPage" */ '@/views/MyPage.vue');
const ProviderReservations = () =>
  import(/* webpackChunkName: "ProviderReservations" */ '@/views/ProviderReservations.vue');
const ProviderReservationsCalendar = () =>
  import(/* webpackChunkName: "ProviderReservationsCalendar" */ '@/views/ProviderReservationsCalendar.vue');
const ProviderCalendar = () => import(/* webpackChunkName: "ProviderCalendar" */ '@/views/ProviderCalendar.vue');
const AdminProviderCalendar = () =>
  import(/* webpackChunkName: "AdminProviderCalendar" */ '@/views/AdminProviderCalendar.vue');
const UserReservations = () => import(/* webpackChunkName: "UserReservations" */ '@/views/UserReservations.vue');
const Messages = () => import(/* webpackChunkName: "Messages" */ '@/views/Messages.vue');
const Providers = () => import(/* webpackChunkName: "Providers" */ '@/views/Providers.vue');
const Articles = () => import(/* webpackChunkName: "Articles" */ '@/views/Articles.vue');
const Article = () => import(/* webpackChunkName: "Article" */ '@/views/Article.vue');
const Profile = () => import(/* webpackChunkName: "Profile" */ '@/views/Profile.vue');
const ProviderReserve = () => import(/* webpackChunkName: "ProviderReserve" */ '@/views/Reserve.vue');
const FirstReserve = () => import(/* webpackChunkName: "FirstReserve" */ '@/views/FirstReserve.vue');
const FirstReserveForm = () => import(/* webpackChunkName: "FirstReserveForm" */ '@/views/FirstReserveForm.vue');
const EditArticle = () => import(/* webpackChunkName: "EditArticle" */ '@/views/admin/EditArticle.vue');

const PersonalTraining = () =>
  import(/* webpackChunkName: "PersonalTraining" */ '@/views/training-plan/PersonalTraining.vue');
const AnimalFlow = () => import(/* webpackChunkName: "AnimalFlow" */ '@/views/training-plan/AnimalFlow.vue');
const MaternityTraining = () =>
  import(/* webpackChunkName: "MaternityTraining" */ '@/views/training-plan/MaternityTraining.vue');
const AozoraStretch = () => import(/* webpackChunkName: "AozoraStretch" */ '@/views/training-plan/AozoraStretch.vue');
const BodyMaintenance = () =>
  import(/* webpackChunkName: "BodyMaintenance" */ '@/views/training-plan/BodyMaintenance.vue');
const DietCourse = () => import(/* webpackChunkName: "DietCourse" */ '@/views/training-plan/DietCourse.vue');

const authFree = { requiresAuth: false, requiresProvider: false, requiresAdmin: false };
const authUser = { requiresAuth: true, requiresProvider: false, requiresAdmin: false };
const authProvider = { requiresAuth: true, requiresProvider: true, requiresAdmin: false };
const authAdmin = { requiresAuth: true, requiresProvider: false, requiresAdmin: true };

const routes: RouteConfig[] = [
  { path: '/terms-of-service', name: 'terms-of-service', component: TermsOfService, meta: authFree },
  { path: '/privacy-policy', name: 'privacy-policy', component: PrivacyPolicy, meta: authFree },
  { path: '/contact', name: 'contact', component: Contact, meta: authFree },
  { path: '/offer', name: 'offer', component: Offer, meta: authFree },
  { path: '/faq', name: 'faq', component: Faq, meta: authFree },
  { path: '/price', name: 'price', component: Price, meta: authFree },
  { path: '/gallery', name: 'gallery', component: Gallery, meta: authFree },

  { path: '/training-plan/personal-training', name: 'personal-training', component: PersonalTraining, meta: authFree },
  { path: '/training-plan/animal-flow', name: 'animal-flow', component: AnimalFlow, meta: authFree },
  {
    path: '/training-plan/maternity-training',
    name: 'maternity-training',
    component: MaternityTraining,
    meta: authFree,
  },
  { path: '/training-plan/aozora-stretch', name: 'aozora-stretch', component: AozoraStretch, meta: authFree },
  { path: '/training-plan/body-maintenance', name: 'body-maintenance', component: BodyMaintenance, meta: authFree },
  { path: '/training-plan/diet-course', name: 'diet-course', component: DietCourse, meta: authFree },
  { path: '/training-plan', name: 'training-plan', component: TrainingPlan, meta: authFree },

  { path: '/sign-in', name: 'sign-in', component: SignIn, meta: authFree },
  { path: '/welcome', name: 'sign-up', component: SignUp, meta: authFree },
  { path: '/complete', name: 'complete', component: Complete, meta: authFree },

  { path: '/verify', name: 'verify', component: Verify, meta: authFree },

  { path: '/articles/:article', name: 'article', component: Article, meta: authFree },
  { path: '/articles', name: 'articles', component: Articles, meta: authFree },

  { path: '/profile', name: 'profile', component: Profile, meta: authUser },
  { path: '/my-page', name: 'my-page', component: MyPage, meta: authUser },
  { path: '/messages/:contact', name: 'messages-contact', component: Messages, meta: authUser },
  { path: '/messages', name: 'messages', component: Messages, meta: authUser },

  { path: '/user-reservations', name: 'user-reservations', component: UserReservations, meta: authUser },

  {
    path: '/providers/:provider/reserve',
    name: 'provider-reserve',
    component: ProviderReserve,
    meta: authFree,
  },
  { path: '/providers/:provider', name: 'provider', component: Providers, meta: authFree },
  { path: '/first-reserve', name: 'first-reserve', component: FirstReserve, meta: authFree },
  { path: '/first-reserve-form', name: 'first-reserve-form', component: FirstReserveForm, meta: authFree },
  { path: '/providers', name: 'providers', component: Providers, meta: authFree },

  { path: '/provider-calendar', name: 'provider-calendar', component: ProviderCalendar, meta: authProvider },

  {
    path: '/provider-reservations',
    name: 'provider-reservations',
    component: ProviderReservations,
    meta: authProvider,
  },
  {
    path: '/provider-reservations-calendar',
    name: 'provider-reservations-calendar',
    component: ProviderReservationsCalendar,
    meta: authProvider,
  },

  { path: '/admin', name: 'admin', component: Admin, meta: authAdmin },
  { path: '/edit-article/:article', name: 'edit-article', component: EditArticle, meta: authAdmin },

  { path: '/contact-messages/:contact', name: 'contact-messages-contact', component: Messages, meta: authAdmin },
  { path: '/contact-messages', name: 'contact-messages', component: Messages, meta: authAdmin },

  {
    path: '/admin-provider-reservations-calendar',
    name: 'admin-provider-reservations-calendar',
    component: ProviderReservationsCalendar,
    meta: authAdmin,
  },

  {
    path: '/admin-provider-calendar',
    name: 'admin-provider-calendar',
    component: AdminProviderCalendar,
    meta: authAdmin,
  },

  { path: '/not-found', name: 'not-found', component: NotFound, meta: authFree },

  { path: '/popup-professional-level', name: 'popup-professional-level', component: Home, meta: authFree },
  { path: '/popup-nutritional-guidance', name: 'popup-nutritional-guidance', component: Home, meta: authFree },

  { path: '/', name: 'home', component: Home, meta: authFree },
  { path: '*', redirect: '/' },
]
  .map((item) => {
    if (item.path == '*' || item.path == '/') return [item];
    return [item, { path: `${item.path}/*`, redirect: `${item.path}` }];
  })
  .flat();

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if ((to.name && to.name.startsWith('popup-')) || (from.name && from.name.startsWith('popup-'))) {
      // ポップアップ系はスクロールしない
      return;
    }
    if (to.hash) {
      return { selector: to.hash, offset: { x: 0, y: 64 } };
    } else if (from.path != to.path && savedPosition) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(savedPosition);
        }, 1000);
      });
    } else if (from.path != to.path) {
      return { x: 0, y: 0 };
    }
  },
});

if (process.env.NODE_ENV === 'production') {
  Vue.use(
    VueGtag,
    {
      appName: 'CoCoDakara Body Design',
      pageTrackerScreenviewEnabled: true,
      config: { id: 'G-428296XML2' },
    },
    router,
  );
}

Hub.listen('auth', async (data) => {
  switch (data.payload.event) {
    case 'signIn': {
      const redirect = router.currentRoute.query.redirect;
      if (typeof redirect === 'string') {
        router.push(redirect);
      } else {
        router.push({ name: 'my-page' });
      }
      break;
    }
    case 'signOut': {
      router.push({ name: 'sign-in' }).catch(() => {});
      break;
    }
  }
});

const getAttributes = (user: CognitoUser) => {
  return new Promise((resolve, reject) => {
    user.getUserAttributes((err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
};

router.beforeResolve(async (to, _from, next) => {
  if (shouldReload(to.path)) {
    if (!to.query.reloaded) {
      const fullPathWithReloaded = {
        path: to.path,
        query: { ...to.query, reloaded: '1' },
      };
      return (window.location.href = router.resolve(fullPathWithReloaded).href);
    } else {
      next();
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let signedIn = null as CognitoUser | null;
    signedIn = await AmplifyAuth.currentAuthenticatedUser().catch(() => {});
    if (!signedIn) {
      return next({
        path: '/sign-in',
        query: {
          redirect: to.fullPath,
        },
      });
    }

    let verified = false;
    await getAttributes(signedIn).then((result) => {
      for (const v of result as { Name: string; Value: string }[]) {
        if (v['Name'] == 'email_verified') {
          verified = v['Value'] == 'true';
        }
      }
    });
    if (!verified) {
      return next({
        path: '/verify',
        query: {
          redirect: to.fullPath,
        },
      });
    }

    if (to.matched.some((record) => record.meta.requiresProvider)) {
      const user = await getUser(signedIn.getUsername());
      if (user.providerStatus != 'provider') {
        return next({
          path: '/',
          query: {
            redirect: to.fullPath,
          },
        });
      }
    }
    if (to.matched.some((record) => record.meta.requiresAdmin)) {
      const user = await getUser(signedIn.getUsername());
      if (user.admin != true) {
        return next({
          path: '/',
          query: {
            redirect: to.fullPath,
          },
        });
      }
    }
    return next();
  }
  return next();
});

export default router;
