const config = {
  mainFacility: '9878e975-d060-430b-a59e-e65fd57628fc',
  googlePlaceId: 'ChIJexSN7DiLGGARcV-_JHR9AKY',
  assetsBucketUrl: 'https://s3.ap-northeast-1.amazonaws.com/net.cocodakara.bodydesign-dev.assets/',
  v2PathPatternsToReload: [
    '/',
    '/terms-of-service',
    '/privacy-policy',
    '/faq',
    '/price',
    '/gallery',
    '/training-plan*',
    '/articles*',
  ],
};

export default config;
